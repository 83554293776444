import { gql } from '@apollo/client'

export const GET_PARTNER_INFORMATION = gql`
  query {
    getPartner {
      PartnerID
      UserID
      name
      middle_name
      family_name
      email
      FormatAddress
      home_phone
      mobile_phone
      curp
      rfc
      Permissions {
        appModule
        nameModule
        permissions {
          appModuleAction
          grantedAccess
          isActive
          descriptionModule
          path
        }
      }
      Role {
        RoleID
        Description
      }
      Store {
        StoreID
        Name
        StoreType {
          Description
        }
        Agency {
          StrategicUnit {
            StrategicUnitName
          }
        }
        Phone
        Device {
          DeviceID
          Model
          Vendor
        }
      }
    }
  }
`
export const GET_USER_COGNITO = gql`
  query GetCognitoUser($userName: String!) {
    GetCognitoUser(userName: $userName) {
      EstatusUser
    }
  }
`
export const GET_USER_SESSION = gql`
  query IsFirstLogin($userName: String!) {
    IsFirstLogin(userName: $userName) {
      FirstLogin
      message
      mobile_phone
    }
  }
`
export const GET_USER_POS_ID = gql`
  query CheckNumberByPSN($posID: String!) {
    CheckNumberByPSN(posID: $posID) {
      message
      mobile_phone
      cognito_username
    }
  }
`
export const GET_COLLABORATOR_AND_PARTNERS_DATA = gql`
  query {
    getCollaboratorsAndPartnerData {
      partnerID
      userID
      name
      middleName
      familyName
      roleID
      roleDescription
    }
  }
`
