/* istanbul ignore file */
import React from 'react'
import { Form, Row, Col, Input, FormInstance } from 'antd'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
//Styled Components

//Assets
import {
  StyledButtonPrimary,
  StyledButtonText
} from '../Shared/Button/styledComponent'

type props = {
  handleForgetPassword: any
  form: FormInstance
  loading: boolean
}

const LazyFormContent = loadable(() => import('../FormContent'))

const ForgetPasswordComponent: React.FC<props> = ({
  handleForgetPassword,
  form,
  loading
}: any) => {
  const content = () => {
    return (
      <>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              name="userName"
              label="Número de celular"
              rules={[
                {
                  required: true,
                  message: 'Número de teléfono incorrecto',
                  pattern: RegExp(
                    '^([+]?[/s0-9]+)?(/d{3}|[(]?[0-9]+[)])?([-]?[/s]?[0-9])+$'
                  )
                }
              ]}
            >
              <Input
                inputMode="tel"
                placeholder="Número de celular 10 dígitos"
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item>
              <StyledButtonPrimary
                role="button"
                type="submit"
                data-testid="send-btn"
              >
                ENVIAR
              </StyledButtonPrimary>
              <StyledButtonText
                role="button"
                type="button"
                onClick={() => navigate('/auth/')}
                data-testid="close-btn"
              >
                CERRAR
              </StyledButtonText>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
  return (
    <>
      <LazyFormContent
        content={content()}
        form={form}
        loading={loading}
        onFinish={handleForgetPassword}
        titleForm={'Restaurar contraseña'}
        subtitleForm={
          'Ingresa tu número de celular para recuperar la contraseña a través de un código vía SMS'
        }
      />
    </>
  )
}

export default ForgetPasswordComponent
