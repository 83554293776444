import { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'
import formatedAmplifyCognitoErrorMessages from '../../data/constants/amplifyCognitoErrorMessages'
import { translateAmplifyCognitoErrorResponse } from '../../utils/general'

const useErrorHandler: (
  apolloError: ApolloError | undefined,
  loading: boolean
) => string = (apolloError, loading) => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (apolloError && !loading) {
      if (apolloError.graphQLErrors) {
        apolloError.graphQLErrors.forEach((error) => {
          const message: string =
            formatedAmplifyCognitoErrorMessages.find(
              (message) => message.code === error.extensions?.code
            )?.es ?? ''
          setMessage(message)
        })
      } else {
        setMessage(translateAmplifyCognitoErrorResponse(apolloError.message))
      }
    }
  }, [apolloError])

  return message
}

export default useErrorHandler
