// Packages
import { useLocation } from '@reach/router'

// Constants
import moment from 'moment'
import constants from '../../data/constants'
import { objInterface } from '../../types/General'

export const translateAmplifyCognitoErrorResponse: (
  errorResponse: string
) => string = (errorResponse) => {
  const { amplifyCognitoErrorMessages } = constants
  const errorTranslated = amplifyCognitoErrorMessages.filter(
    ({ en }) => en === errorResponse
  )
  return errorTranslated.length ? errorTranslated[0].es : errorResponse
}

export const convertNumberToMoney = (number: number): string => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'USD'
  }).format(number)
}

export const truncateNumber = (value: number, numTrunc: number): string => {
  return Number(value).toFixed(numTrunc)
}

export const getDateFromDigits = (strNumber: string): string => {
  const year = strNumber.substring(0, 4)
  const month = strNumber.substring(4, 6)
  const day = strNumber.substring(6) || '01'
  const date = year + '-' + month + '-' + day
  return date
}

export const getMonthYearFromSixDigits = (
  strNumber: string,
  capitalize = true
): string => {
  const date = getDateFromDigits(strNumber)
  const dateFormat = moment(date).format('MMMM YYYY')

  return capitalize ? capitalizeFirstLetter(dateFormat) : dateFormat
}

export const getMonthDayFromEightDigits = (
  strNumber: string,
  capitalize = true
): string => {
  const date = getDateFromDigits(strNumber)
  const dateFormat = moment(date).format('dddd D')

  return capitalize ? capitalizeFirstLetter(dateFormat) : dateFormat
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeFirstsLettersOfString = (string: string): string => {
  const sentence = string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
  return sentence
}

export const isActualMonth = (date: moment.MomentInput): boolean => {
  const actualDateFormat = moment().format('YYYYMM')
  const dateFormat = moment(date).format('YYYYMM')
  return actualDateFormat === dateFormat
}

export const getStartAndEndDayOfMonth = (date: moment.MomentInput) => {
  const start = moment(date).format('YYYY-MM-01')
  const end = moment(date).format('YYYY-MM-') + moment(date).daysInMonth()
  return { start, end }
}

export const getStartAndEndMonthIfActualMonth = (date: moment.MomentInput) => {
  const { start, end } = getStartAndEndDayOfMonth(date)
  const actualDay = moment(date).format('YYYY-MM-') + moment().format('DD')
  const to = isActualMonth(date) ? actualDay : end
  return { from: start, to }
}

export const getLocation: () => string = () => {
  return useLocation().pathname.split('/')[1]
}

export const getFullLocation: () => string = () => {
  return useLocation().pathname
}

export const convertArrayToObj = (
  array: any[],
  keyArray: string
): objInterface => {
  const obj: objInterface = {}
  array.forEach((item) => {
    const key = item[keyArray]
    obj[key] = item
  })
  return obj
}

export const parseIntMonthToString = (month: string | number): string => {
  const validateMonth = `0${Number(month) - 1}`
  const monthString: string = moment().month(validateMonth).format('MMMM')
  return capitalizeFirstsLettersOfString(monthString)
}

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const onlyUniqueWithValue = (
  value: string | number,
  index: number,
  self: (string | number)[]
): boolean => {
  return self.indexOf(value) === index && !!value
}

export const filterUniqueWithValue = (array: (string | number)[]) => {
  return array.filter(onlyUniqueWithValue)
}
