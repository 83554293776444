// Assets
import amplifyCognitoErrorMessages from './amplifyCognitoErrorMessages'
import exportExcelConfig from './exportExcelConfig'
import regularExpressions from './regularExpressions'
import popUpContent from './popUpContent'
import faqs from './faqs'

export default {
  amplifyCognitoErrorMessages,
  exportExcelConfig,
  regularExpressions,
  popUpContent,
  faqs
}
