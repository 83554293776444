//Packages
import React from 'react'
import { navigate } from 'gatsby'

//Layouts
import { MainLayout } from '../../../layout/MainLayout'

//Containers
import ForgetPasswordContainer from '../../../containers/ForgetPasswordContainer'

// Assets
import { isBrowser, isLoggedIn } from '../../../utils/storage'

const ForgetPasswordPage: React.FC = () => {
  return (
    <MainLayout>
      {!isLoggedIn() && <ForgetPasswordContainer />}
      {isLoggedIn() && isBrowser() && navigate('/')}
    </MainLayout>
  )
}

export default ForgetPasswordPage
