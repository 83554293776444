const exportExcelConfig = {
  ['ReportSales']: {
    columnsByMonths: [{ wch: 15 }, { wch: 15 }],
    columnsByMonthsDays: [
      { wch: 15 },
      { wch: 15 },
      { wch: 8 },
      { wch: 20 },
      { wch: 10 },
      { wch: 20 },
      { wch: 10 },
      { wch: 125 }
    ],
    styleDate: {
      font: {
        sz: 14,
        color: { rgb: '001529' }
      }
    },
    styleTitle: {
      font: {
        sz: 18,
        bold: true,
        color: { rgb: 'ffc70f' }
      }
    },
    style: {
      font: {
        sz: 14,
        bold: true,
        color: { rgb: 'FFFFFFF' }
      },
      fill: { fgColor: { rgb: '001529' } },
      alignment: { wrapText: true, horizontal: 'center' }
    }
  }
}

export default exportExcelConfig
