//Packages
import React, { useEffect, useState } from 'react'
import { resetPassword } from 'aws-amplify/auth'
import { Form, Result, Modal } from 'antd'
import { navigate } from 'gatsby'
import { ApolloError, useLazyQuery } from '@apollo/client'

//Components
import ForgetPasswordComponent from '../../components/ForgetPassword'

//Configuracion
import '../../../config/amplify'

//Types
import AuthTypes from '../../types/AuthTypes'

// Utils
import useErrorHandler from '../../hooks/useErrorHandler'

//Assets
import sendMail from '../../data/assets/images/sendMail.png'
import warning from '../../data/assets/icons/warning-icon.svg'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'

// Query
import { GET_USER_SESSION } from '../../graphql/queries/partner'

const ForgetPasswordContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState({
    isModalVisible: false,
    content: <></>
  })
  const [userData, setUserdata] = useState<AuthTypes.IsFirstLogin>()
  const [apolloError, setApolloError] = useState<ApolloError>()
  const [formForgetPassword] = Form.useForm<AuthTypes.ForgetPassword>()

  const errorMessage = useErrorHandler(apolloError, loading)

  const [
    onSubmit,
    { data: dataUser, loading: dataUserLoading, error: dataUserError }
  ] = useLazyQuery(GET_USER_SESSION)

  const handleForgetPassword = async (data: AuthTypes.ForgetPassword) => {
    setLoading(true)
    onSubmit({
      variables: {
        userName: data.userName
      }
    })
  }

  const submitUsername = async () => {
    resetPassword({ username: `+52${userData?.mobile_phone}` })
      .then((success) => {
        const message =
          success.nextStep?.codeDeliveryDetails?.attributeName === 'email'
            ? `Se ha enviado un código de verificación vía Email a la dirección ${success.nextStep?.codeDeliveryDetails?.destination}`
            : `Se ha enviado código de verificación vía SMS al número ${success.nextStep?.codeDeliveryDetails?.destination}`
        setResult({
          isModalVisible: true,
          content: (
            <Result
              key={`success-${Math.random()}`}
              icon={<img src={sendMail} alt="sendmail" />}
              status={'success'}
              subTitle={message}
              extra={[
                <StyledButtonPrimary
                  type="button"
                  onClick={() =>
                    navigate(
                      `/auth/reset-password?userName=${userData?.mobile_phone}&code=sms`
                    )
                  }
                >
                  Aceptar
                </StyledButtonPrimary>
              ]}
            />
          )
        })
      })
      .catch((error) => {
        setLoading(false)
        setApolloError(error)
      })
  }

  const handleUserError = () => {
    setLoading(false)

    setResult({
      isModalVisible: true,
      content: (
        <Result
          key={`success-${Math.random()}`}
          icon={<img src={warning} alt="warning" />}
          status={'error'}
          subTitle={`El usuario no esta registrado`}
          extra={[
            <StyledButtonPrimary
              type="button"
              onClick={() =>
                setResult({ content: <></>, isModalVisible: false })
              }
            >
              Aceptar
            </StyledButtonPrimary>
          ]}
        />
      )
    })
  }

  useEffect(() => {
    if (dataUser && dataUser.IsFirstLogin && !dataUserError) {
      setUserdata(dataUser.IsFirstLogin)
    }
  }, [dataUser])

  useEffect(() => {
    if (userData && userData.message !== 'NOT FOUND') {
      submitUsername()
    } else if (userData && userData.message === 'NOT FOUND') {
      handleUserError()
    }
  }, [userData])

  useEffect(() => {
    if (errorMessage && apolloError) {
      setResult({
        isModalVisible: true,
        content: (
          <Result
            key={`success-${Math.random()}`}
            status={'error'}
            subTitle={errorMessage}
            extra={[
              <StyledButtonPrimary
                type="button"
                onClick={() =>
                  setResult({ content: <></>, isModalVisible: false })
                }
              >
                Aceptar
              </StyledButtonPrimary>
            ]}
          />
        )
      })
    }
  }, [errorMessage, apolloError])

  return (
    <>
      <ForgetPasswordComponent
        handleForgetPassword={handleForgetPassword}
        form={formForgetPassword}
        loading={loading || dataUserLoading}
      />
      <Modal
        visible={result.isModalVisible}
        footer={null}
        onCancel={undefined}
        onOk={undefined}
      >
        {result.content}
      </Modal>
    </>
  )
}

export default ForgetPasswordContainer
