import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import BaseStyles from '../styles/base'
import theme from '../styles/theme'
import { isBrowser } from '../utils/storage'
import SearchEngineOptimization from '../components/SEO'

const MainDiv = styled.div`
  background-color: white;
  color: ${(props) => props.theme.colors.primary};
`

type props = {
  children: any
}

export const MainLayout: React.FC<props> = ({ children }) => {
  if (isBrowser()) {
    window.scrollTo(0, 0)
  }

  return (
    <ThemeProvider theme={theme}>
      <SearchEngineOptimization title="Portal de Empresarios" />
      <BaseStyles />
      <MainDiv>{children}</MainDiv>
    </ThemeProvider>
  )
}
