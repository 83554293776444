const faqs = [
  {
    question: `¿Qué es BEES Pay?`,
    answer: `Somos parte de Grupo Modelo (AB-Inbev) y creamos soluciones tecnológicas para ayudarte a crecer tu negocio. Con tu cuenta BEES Pay podrás aceptar pagos con tarjetas de crédito, débito y vales sin necesidad de que seas cliente de un banco, ofrecer pago de servicios y tiempo aire a tus clientes, así como llevar el control de tus ventas.`
  },
  {
    question: `¿Puedo transferir mi saldo a una cuenta bancaria?`,
    answer: `Por el momento no es posible, el saldo que ingreses a tu cuenta BEES Pay solo puede ser utilizado para pagar servicios como telefonía, internet, televisión, luz, agua, gas, impuestos, tesorería, transporte, con los que tengamos convenio, y para recargar tiempo aire.`
  },
  {
    question: `¿Dónde puedo usar mi saldo?`,
    answer: `Por el momento solo puedes utilizarlo para pagar servicios como telefonía, internet, televisión, luz, agua, gas, impuestos, tesorería, transporte, con los que tengamos convenio, y para recargar tiempo aire.`
  },
  {
    question: `¿Cómo me registro?`,
    answer: `En la pantalla inicial de la app selecciona Crear cuenta nueva, necesitarás proporcionar tu número celular y verificarlo con el código que te enviaremos. Una vez que hayas ingresado a la app con la cuenta que acabas de crear, selecciona Activar para disfrutar todos los beneficios de BEES Pay. Ten a la mano una identificación vigente (IFE, INE o pasaporte), la necesitaremos para validar tu información.`
  },
  {
    question: `¿Tengo que pagar por usar BEES Pay?`,
    answer: `El uso de BEES Pay es gratuito. No te cobraremos manejo de cuenta, contratos ni plazos forzosos, pero cada movimiento de dinero en tu cuenta tiene una comisión de 1.99% + IVA. La terminal física sí tiene un costo de $299, y la puedes conseguir a través de nuestro sitio web.`
  },
  {
    question: `¿Cómo activo mi terminal?`,
    answer: `Prende tu terminal y activa el Bluetooth de tu celular. Aparecerá en tu pantalla automáticamente. Selecciónala y sigue las instrucciones.`
  },
  {
    question: `¿Cómo recargo saldo en mi cuenta?`,
    answer: `Puedes hacerlo con tarjeta de crédito o débito a través de tu terminal física o recargando en comercios seleccionados. En la pantalla inicial selecciona Recargar y a continuación cómo quieres agregar saldo: Tarjeta de crédito o crédito o Recarga en comercios. Si eliges Tarjeta de crédito o débito, ingresa el monto de la recarga y asegúrate que la terminal física está conectada, inserta o desliza la tarjeta, autoriza y listo. Si eliges Recarga en comercios, selecciona un comercio cercano en la lista e ingresa el monto de la recarga (máximo $5,000). Se generará un código que tienes que presentar en la caja del comercio.`
  },
  {
    question: `¿Cómo cambio mi correo electrónico?`,
    answer: `En la pantalla inicial selecciona Perfil > Editar perfil. A la derecha de donde se encuentra tu correo electrónico selecciona Editar. Ingresa tu nuevo correo electrónico, selecciona Guardar y listo.`
  },
  {
    question: `¿Cómo cambio mi contraseña?`,
    answer: `En la pantalla inicial selecciona Perfil > Opciones de seguridad > Contraseña. Ingresa tu contraseña actual y selecciona Confirmar. Crea tu nueva contraseña y selecciona Continuar. Ingresa el código que te enviamos a tu teléfono, selecciona Cambiar contraseña y listo.`
  },
  {
    question: `Olvidé mi contraseña`,
    answer: `Por seguridad, si la olvidaste, será necesario que crees una nueva. En tu pantalla de inicio, donde solicitamos tu contraseña anterior, selecciona ¿Olvidaste tu contraseña? e ingresa los 10 dígitos de tu número celular. Te enviaremos un código de 6 dígitos que deberás ingresar y, posteriormente responder a tu pregunta de seguridad. Listo, ahora podrás crear una nueva contraseña.

    "Ayuda"

    En la pantalla inicial selecciona Perfil > Opciones de seguridad > NIP. Ingresa tu NIP actual y selecciona Confirmar. Crea tu nuevo NIP, selecciona Guardar y listo, ya puedes usar tu NIP.
    `
  },
  {
    question: `¿Qué servicios puedo comprar/pagar con el saldo de mi cuenta?`,
    answer: `A través de BEES Pay puedes pagar más de 80 servicios: telefonía, internet, televisión, luz, agua, gas, impuestos, tesorería, transporte, tarjetas de regalo y hasta ventas por catálogo.`
  },
  {
    question: `¿Qué es un beneficiario?`,
    answer: `Es una persona de confianza que eliges durante el proceso de activación de tu cuenta para que reciba el total de tu saldo disponible en caso de que surja una adversidad.`
  },
  {
    question: `¿Cuál es el monto máximo que puedo cobrar con BEES Pay?`,
    answer: `Por seguridad, solo puedes cobrar hasta $15,000 por transacción y cada tarjeta solo puede ser usada dos veces cada 24 horas. Puedes solicitar un aumento del límite por transacción al correo electrónico atencion@sihay.mx enviando:
      - Identificación oficial vigente del titular, digitalizada por ambos lados.
      - Nombre del negocio y dirección física.
      - Breve descripción del negocio, incluyendo los servicios y/o productos que ofrece.
      - En caso de emitir facturas, 3 de ellas en PDF con fecha no mayor a 3 meses.En máximo 72 horas hábiles, el cambio quedará aplicado y recibirás una confirmación por correo electrónico.
    `
  },
  {
    question: `¿Cómo puedo cobrar con tarjetas extranjeras?`,
    answer: `Puedes solicitar esta opción enviando desde el correo electrónico que registraste en la app a atencion@sihay.mx:
      -Identificación oficial vigente del titular, digitalizada por ambos lados.
      -Comprobante de domicilio del negocio.
      -Giro y monto en pesos del ticket promedio de tu negocio.En máximo 72 horas hábiles, activaremos esta opción en tu cuenta y recibirás una confirmación por correo electrónico. Ten en cuenta que los cobros realizados con tarjetas extranjeras tienen las mismas comisiones y condiciones de pago que las tarjetas nacionales.
    `
  },
  {
    question: `¿Por qué me piden datos personales?`,
    answer: `Usamos tu identificación oficial para validar tu CURP y tu identidad. Queremos estar seguros de que eres tú quien está solicitando esta cuenta y no alguien más con tu nombre, por eso también te pedimos una foto de tu rostro y tu ubicación. Nos apegamos a la regulación para poder brindarte una cuenta segura y que se active solo si tú lo autorizas.`
  },
  {
    question: `¿Mi dinero está seguro dentro de BEES Pay?`,
    answer: `Tu dinero está seguro. Cuando hagas una recarga, te informaremos cuando esté lista dentro de tu cuenta, podrás verla en tu saldo disponible y descargar tus estados de cuenta en la sección Resumen.`
  },
  {
    question: `Mi cuenta se bloqueó al intentar iniciar sesión, ¿cómo la desbloqueo?`,
    answer: `Por razones de seguridad, es necesario que crees una nueva contraseña. En la pantalla inicial de la app selecciona ¿Olvidaste tu contraseña?, ingresa tu número celular. Validaremos tu identidad con tu pregunta de seguridad y el código de 6 dígitos que te enviaremos y podrás crear una nueva contraseña e ingresar con ella a la app.`
  },
  {
    question: `Mi número de celular se bloqueó al intentar crear mi cuenta, ¿cómo lo desbloqueo?`,
    answer: `Por razones de seguridad será necesario que nos llames al 5578673627 para ayudarte a desbloquearlo`
  },
  {
    question: `¿Necesito tener saldo en mi cuenta para poder hacer recargas a celulares y pagar servicios?`,
    answer: `Sí, para cualquier movimiento de dinero, ya sea para hacer recargas a celular o pagar servicios, es necesario que en tu cuenta exista el saldo suficiente.`
  },
  {
    question: `¿Cómo cambio mi número celular?`,
    answer: `Por seguridad, deberás llamarnos al Centro de Atención a Clientes de BEES Pay al 5578673627 y un ejecutivo te dará instrucciones para hacer el cambio.`
  },
  {
    question: `¿Cuánto tiempo tardará en reflejarse mi saldo después de hacer una recarga?`,
    answer: `Tu saldo se mostrará en cuanto recibamos la notificación de pago. Si después de unos minutos no se refleja, llámanos al 5578673627.`
  },
  {
    question: `¿Mi cuenta en BEES Pay es igual a una cuenta bancaria?`,
    answer: `Tu cuenta BEES Pay funciona como un monedero electrónico. El dinero que ingreses se convierte en saldo disponible para que lo utilices en los diferentes servicios que ofrecemos dentro de la app. Una cuenta bancaria requiere un saldo mínimo para no cobrarte manejo de cuenta; nosotros no.`
  }
]

export default faqs
