import React from 'react'
const blueContent = (data: string) => (
  <span style={{ color: '#0b4793' }}>{data}</span>
)
const bold = (data: string) => <span style={{ fontWeight: '700' }}>{data}</span>

const popUpContent = [
  {
    Title: 'Registra tu cuenta',
    Content: (
      <>
        {bold('Registra desde tu celular')} para disfrutar de los beneficios de
        BEES Pay en tu WizarPOS.
        <span
          style={{ display: 'block', paddingTop: '20px', fontWeight: '700' }}
        >
          {blueContent('Presiona el botón:')}
        </span>
        <span style={{ display: 'block', fontWeight: '700' }}>
          {blueContent('"Hacer vinculación"')}
        </span>
      </>
    )
  },
  {
    Title: 'Vincula BEES Pay',
    Content: (
      <>
        <span style={{ display: 'block' }}>
          {bold('Se descargará BEES Pay en tu celular.')}
        </span>
        Con un par de documentos validamos tu identidad y listo, habrás
        registrado BEES Pay en tu WizarPOS
      </>
    )
  },
  {
    Title: 'Termina el proceso de activación.',
    Content: (
      <>
        En los siguientes días, llegará una notificación a tu celular. Te pedirá
        dar click en "Activar", en la Wallet del WizarPOS.
        <span style={{ display: 'block' }}>{bold('¡Terminal activada!')}</span>
        Ya podrás hacer los pagos con tarjeta.
      </>
    )
  }
]

export default popUpContent
